import axios from "axios";

// export const Baseurl = "http://ec2-15-207-233-45.ap-south-1.compute.amazonaws.com:8000/";

export const Baseurl = "https://devapi.wafirservice.com/";
export const ImageUrl = "https://wafir-app-assets.s3.ap-south-1.amazonaws.com/";

// export const Baseurl = "https://api2.wafirservice.com/";
// export const ImageUrl =
// 	"https://wafir-app-assets-prod.s3.me-south-1.amazonaws.com/";

export const POST = async function (route, data, header = "") {
	try {
		if (header.headerStatus === true) {
			return await axios.post(Baseurl + route, data, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			});
		} else {
			return await axios.post(Baseurl + route, data);
		}
	} catch (err) {
		console.log(err);
	}
};

export const UPLOAD = async function (route, data, header = "") {
	try {
		if (header.headerStatus === true) {
			return await axios.post(Baseurl + route, data, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "multipart/form-data",
				},
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const PUT = async function (route, data, header = "") {
	try {
		return await axios.put(Baseurl + route, data, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		});
	} catch (err) {
		console.log(err);
	}
};

export const DELETE = async function (route) {
	try {
		return await axios.delete(Baseurl + route, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		});
	} catch (err) {
		console.log(err);
	}
};

export const GET = async function (route) {
	try {
		return await axios.get(Baseurl + route, {
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		});
	} catch (err) {
		console.log(err);
	}
};
